import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import {
  Drawer,
  Button,
  Notification,
  Message,
} from 'rsuite';

import {
  updateServico,
  addServico,
  saveServico,
} from '../../store/modules/servico/actions';

import util from '../../services/util';

const ServicoDrawer = ({combo}) =>{
  const { servico, form, components, behavior } = useSelector(
    (state) => state.servico
  )
  const { estabelecimento } = useSelector(
    state => state.estabelecimento
  )
  const dispatch = useDispatch();

  const setServico = (key, value) => {
    dispatch(
      updateServico({
        servico: { ...servico, [key]: value },
      })
    );
  };

  const setComponents = (component, state) => {
    dispatch(
      updateServico({
        components: { ...components, [component]: state },
      })
    );
  };
  const save = () => {
    if (
      !util.allFields(servico, [
        'titulo',
        'descricao'
      ])
    ) {
      Notification.error({
        placement: 'topStart',
        title: 'Calma lá!',
        description: 'Antes de prosseguir, preencha todos os campos!',
      });
      return false;
    }
    if(servico.categoria !== 'Odonto' && !servico.preco){
      Notification.error({
        placement: 'topStart',
        title: 'Calma lá!',
        description: 'Nessa categoria você também precisa preencher o Preço!',
      });
      return false;
    }
    if(servico?.preco === '0' || servico?.preco1vez === '0' ){
      Notification.error({
        placement: 'topStart',
        title: 'Calma lá!',
        description: 'O preço do serviço não pode ser 0',
      });
      return false
    }
    if(servico.categoria === 'Odonto'){
      setServico('preco', null)
    }
    if(combo){
      setServico('categoria', "Combo")
    }

    if (behavior === 'create') {
      dispatch(addServico());
    } else {
      dispatch(saveServico());
    }
  };

  useEffect(() =>{
    if(!!estabelecimento?.categorias?.length){
      setServico('categoria', estabelecimento?.categorias[0])
    }
  }, [components.drawer])
  return(
    <Drawer
        show={components.drawer}
        size="sm"
        onHide={() => setComponents('drawer', false)}
    >
      <Drawer.Body className="col-12 my-4">
        <h3>{behavior === 'create' ? 'Criar' : 'Atualizar'} {combo? "combo" : "serviço"}</h3>
          <div className="col-13 my-3">
          <Message
            showIcon
            type="info"
            title={combo? "Aqui você pode criar combo com vários serviços" : "Aqui você pode criar um serviço individual."}
            description={combo? "Na descrição coloque Serviço 1 + Serviço 2 + Serviço 3 etc.." : "Ex: Consulta com clínico geral."}
          />               
        </div>
        <div className="row mt-3">
          <div className="form-group col-12">
          <b className="servico-label">Nome do {combo ? "combo" : "serviço"} *</b>
          <input
            type="text"
            className="form-control"
            placeholder="Nome breve do serviço"
            maxLength={95} // Limite máximo de 95 caracteres
            value={servico.titulo}
            onChange={(e) => {
              const inputValue = e.target.value.trimStart(); // Remover espaços em branco no início do valor
              setServico('titulo', inputValue);
            }}
          />
        </div>
          {
            !combo && (
              <div className="form-group col-12">
                <b className="servico-label">Categoria do serviço *</b>
                <select
                  className="form-control"
                  value={combo? "Combo" : servico.categoria}
                  onChange={(e) => setServico('categoria', e.target.value)}
                >
                  {
                    estabelecimento?.categorias?.map((e) =>(
                      <option value={e}>
                        {e}
                      </option>
                    ))
                  }
                  {
                    !estabelecimento?.categorias?.length && (
                      <option value={false}>
                        Você não tem categorias cadastradas
                      </option>
                    )
                  }
                </select>
              </div>
            )
          }
          <>
          {
            servico?.categoria !== "Odonto" && (
              <div className={`form-group ${estabelecimento?.beneficio === 'Sem benefício'? 'col-5' : 'col-4' }`}>
                <b className="servico-label">Preço do {combo ? "combo" : "serviço"} *</b>
                <input                  
                  className="form-control"
                  placeholder="Somente preço inteiro"
                  maxLength={5} // Limite máximo de 5 caracteres
                  value={servico.preco}
                  onChange={(e) => setServico('preco', e.target.value.replace(/[^0-9]/g, ''))}
                />
              </div>
            )
          }
            {
              estabelecimento?.beneficio === 'Com benefício' && (
                <div className="form-group col-4.5">
                  <b className="servico-label">Preço com benefício</b>  <i className="fas fa-question-circle fa-sm" data-toggle="tooltip" data-placement="top" title="Deixe em branco se não optar por oferecer preco promocional." />
                  <input
                    className="form-control"
                    placeholder="Valor com benefício"
                    maxLength={5} // Limite máximo de 5 caracteres
                    value={servico.preco1vez}
                    onChange={(e) => setServico('preco1vez', e.target.value.replace(/[^0-9]/g, ''))}
                  />
                </div>
              )
            }
          </>
          <div className="form-group col-12">
           <b className="servico-label">Responsável técnico pelo anúncio (opcional)</b>
              <input
                type="text"
                className="form-control"
                placeholder="Nome do responsável técnico pelo anúncio"
                maxLength={33} // Limite máximo de 33 caracteres
                value={servico.responsavel}
                onChange={(e) => {
                  const inputValue = e.target.value.trimStart().replace(/[^a-zA-ZÀ-ú\s]/g, ''); // Remover espaços em branco no início do valor e caracteres não alfabéticos
                  setServico('responsavel', inputValue);
                }}
              />
          </div>
          <div className="form-group col-5">
            <b className="servico-label">Conselho</b>
            <select
              className="form-control"
              value={servico?.responsavelConselho}
              onChange={(e) => setServico('responsavelConselho', e.target.value)}
            >
              <option value="">Escolher conselho</option>
              <option value="CRM">CRM</option>
              <option value="CRO">CRO</option>
              <option value="CRP">CRP</option>
              <option value="CRBM">CRBM</option>
              <option value="COREM">COREM</option>
              <option value="CREFITO">CREFITO</option>
              <option value="CRMV">CRMV</option>
              <option value="CREFITO">CRN</option>
              <option value="CRMV">CRFa</option>  
            </select>
          </div>
          <div className="form-group col-3 pl-0">
            <b className="servico-label">UF</b>
            <select
              className="form-control"
              value={servico?.responsavelUf}
              onChange={(e) => setServico('responsavelUf', e.target.value)}
            >
              <option value="">UF</option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </select>
          </div>
          <div className="form-group col-3 pl-0">
            <b className="servico-label">Número</b>
            <input
              className="form-control"
              placeholder="Número"
              value={servico.responsavelNumeracao}
              maxLength={10} // Limite de 10 caracteres
              onChange={(e) => {
              const inputValue = e.target.value.slice(0, 10).replace(/\D/g, ''); // Limitar o valor de entrada em 10 caracteres e remover não números
              setServico('responsavelNumeracao', inputValue);
              }}
              />
            </div>
          <div className="form-group col-12">
            <b className="servico-label">Descrição e Informações *</b>
            <textarea
              rows="7"
              className="form-control"
              placeholder="Descrição e Informações pertinentes sobre o serviço..."
              maxLength={3000} // Limite máximo de 3000 caracteres
              value={servico.descricao}
              onChange={(e) => {
                const inputValue = e.target.value.trimStart(); // Remover espaços em branco no início do texto
                setServico('descricao', inputValue);
              }}
           ></textarea>
          </div>
          <div className="form-group col-12">
            <b className="d-block servico-label">Imagem do {combo? "combo" : "serviço"} PNG, JPEG E JPG (Imagem quadrada) <i className="fas fa-question-circle fa-sm" data-toggle="tooltip" data-placement="top" title="Adicionar imagem aumenta o engajamento." /></b>
            <p className="d-block servico-label" style={{ fontSize: 'smaller' }}>Imagens grandes podem não aparecer aqui. Não se preocupe, clique em SALVAR para concluir.</p>
            <input 
              type="file"
              id="file"
              name="file"
              style={{
                width: 80,
                height: 80,
                border: 'none',
                cursor:  'pointer',
                margin:  0,
                position: 'relative',
                zIndex: 2,
                opacity: 0,
              }}
              accept=".jpg, .jpeg, .png"
              onChange={e => e.target.files[0] && setServico('arquivos', [e.target.files[0]])}
            />
            <img
              alt="..."
              style={{
                width: 80,
                height: 80,
                background:  '#FFF',
                border: 'none',
                position: 'absolute',
                left: 15,
                zIndex: 1,
                borderRadius: 5
              }}
              src={servico?.arquivos[0] ? URL.createObjectURL(servico.arquivos[0]) : servico?.imagem ? `${util.AWS.bucketURL}/${servico?.imagem}` :  'https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg'}
            />
          </div>
        </div>
        <Button
          loading={form.saving}
          color={behavior === 'create' ? 'green' : 'primary'}
          size="lg"
          block
          onClick={() => save()}
          className="mt-3"
        >
          Salvar {combo? "Combo" : "serviço"}
        </Button>
        {behavior === 'update' && (
          <Button
            loading={form.saving}
            color="red"
            size="lg"
            block
            onClick={() => {setComponents('confirmDelete', true)}}
            className="mt-1"
          >
            Remover {combo? "Combo" : "serviço"}
          </Button>
        )}
      </Drawer.Body>
    </Drawer>
  )
}

export default ServicoDrawer;